import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"
import TransitionLink from "gatsby-plugin-transition-link"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import SEO from "components/seo"
import { StateContext } from "components/Context"
import TransitionWrapper from "components/TransitionWrapper"
import Container from "components/Container"
import Banner from "components/Banner"
import { ProjectTimeline, FooterTimeline } from "utils/Animations"

const Project = ({
  data,
  transitionStatus,
  pageContext: { index, previous, next },
}) => {
  const {
    state: { sparks, isLoaded, projectTl },
    dispatch,
  } = useContext(StateContext)

  useEffect(() => {
    if (!sparks || projectTl.totalDuration() > 0) return
    projectTl.add(ProjectTimeline(sparks))
    projectTl.add(FooterTimeline())
    dispatch({
      type: "SET_CURRENT_TIMELINE_DURATION",
      value: projectTl.totalDuration(),
    })

    window.projectTl = projectTl
  }, [sparks, data.contentfulProject.informations, dispatch, projectTl])

  const onEntering = () => {
    projectTl.timeScale(1).play()
  }

  const onEntered = () => {
    if (window.locoscroll) {
      window.locoscroll.start()
      window.locoscroll.update()
    }
    if (!isLoaded) return
    projectTl.timeScale(1).play()
  }

  const onExiting = () => {
    projectTl
      .timeScale(2.3)
      .reverse()
      .then(tl => tl.clear(true))
  }

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <span className="bold">{text}</span>,
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => (
        <a
          className="underline"
          title={children}
          href={node.data.uri}
          rel="noopener noreferrer"
          target="_blank"
        >
          {children}
        </a>
      ),
      [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="pr-4" data-anim-line="">
          {children}
        </p>
      ),
    },
    renderText: text => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },
  }

  return (
    <>
      <SEO lang={"en"} pageTitle={data.contentfulProject.title} />
      <TransitionWrapper
        page="project"
        isLoaded={isLoaded}
        transitionStatus={transitionStatus}
        onEntering={onEntering}
        onEntered={onEntered}
        onExiting={onExiting}
      >
        <Container className="pt-24 md:pt-32 md:pb-24">
          <h1
            className="text-5xl md:text-6xl opacity-0"
            data-anim-project-title=""
          >
            {data.contentfulProject.title}
          </h1>
          {data.contentfulProject.repoUrl && (
            <a
              className="font-secondary underline"
              data-anim-project-link=""
              href={data.contentfulProject.repoUrl}
              target="_blank"
              rel="noreferrer"
            >
              Code
            </a>
          )}
          {data.contentfulProject.websiteUrl && (
            <a
              className={`font-secondary underline ${
                data.contentfulProject.repoUrl ? "pl-4" : ""
              }`}
              data-anim-project-link=""
              href={data.contentfulProject.websiteUrl}
              target="_blank"
              rel="noreferrer"
            >
              Demo
            </a>
          )}
          <div className="flex flex-wrap md:flex-row-reverse mt-12">
            <div className="w-full md:w-8/12">
              {data.contentfulProject.images.map((image, index) => (
                <div
                  key={index}
                  className={`c-image relative transition-opacity duration-1000 delay-100 ease-out ${
                    index > 0 ? "mt-10" : ""
                  }`}
                >
                  <img
                    key={index}
                    className={`ml-auto mr-auto`}
                    src={image.fixed.src}
                    alt={image.title}
                  />
                </div>
              ))}
            </div>
            <div className="w-full md:w-4/12 pt-12 md:pt-0">
              {data.contentfulProject.informations.map((section, index) => {
                return (
                  <section
                    key={section.id}
                    className={`pb-8`}
                    data-anim-section=""
                    data-scroll=""
                    // data-scroll-speed={index > 0 ? "5" : 0}
                  >
                    <h3
                      className="u-outline text-3xl md:text-5xl inline transition duration-500 ease-in-out"
                      data-anim-title=""
                    >
                      {section.title}
                    </h3>
                    <div className="font-secondary">
                      {renderRichText(section.description, options)}
                    </div>
                  </section>
                )
              })}
            </div>
          </div>
        </Container>
        <Banner firstWord="Other" secondWord="Projects" />
        <div className="flex flex-col md:flex-row mt-8 mb-12 md:mt-16 md:mb-24">
          <div className="w-full md:w-6/12 text-center">
            <TransitionLink
              to={`/works${previous.path}`}
              className="u-outline text-4xl transition duration-500 ease-in-out"
              data-other-project=""
              exit={{
                length: 5,
              }}
              entry={{
                delay: 5,
              }}
            >
              {previous.title}
            </TransitionLink>
          </div>
          <div className="w-full md:w-6/12 text-center">
            <TransitionLink
              to={`/works${next.path}`}
              className="u-outline text-4xl transition duration-500 ease-in-out"
              data-other-project=""
              exit={{
                length: 5,
              }}
              entry={{
                delay: 5,
              }}
            >
              {next.title}
            </TransitionLink>
          </div>
        </div>
      </TransitionWrapper>
    </>
  )
}

export default Project

export const query = graphql`
  query ProjectQuery($slug: String) {
    contentfulProject(path: { eq: $slug }, node_locale: { eq: "en" }) {
      path
      title
      repoUrl
      websiteUrl
      images {
        title
        fixed(resizingBehavior: SCALE, quality: 90, width: 800, toFormat: JPG) {
          src
        }
      }
      informations {
        id
        title
        description {
          raw
        }
      }
    }
  }
`
